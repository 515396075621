import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ajax, AjaxConfig } from 'rxjs/ajax';
import { catchError, switchMap, take } from 'rxjs/operators';
import { selectSession } from '../../stores/root.store';
import { HandleAjaxError, HandleAjaxResponse } from './util';

const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
};

@Injectable({
    providedIn: 'root',
})
export class TenantAjaxService {

    static requested: number = 0;
    static completed: number = 0;
    static instances: TenantAjaxService[] = [];

    constructor(
        private store: Store,
    ) { }

    public get<T = any>(tenant: string, url: string) {
        return this.ajax<T>(tenant, 'GET', url);
    }

    public put<T = any>(tenant: string, url: string, body?: any) {
        return this.ajax<T>(tenant, 'PUT', url, body);
    }

    public post<T = any>(tenant: string, url: string, body?: any) {
        return this.ajax<T>(tenant, 'POST', url, body);
    }

    public patch<T = any>(tenant: string, url: string, body?: any) {
        return this.ajax<T>(tenant, 'PATCH', url, body);
    }

    public delete<T = any>(tenant: string, url: string) {
        return this.ajax<T>(tenant, 'DELETE', url);
    }

    private ajax<T>(tenant: string, method: string, url: string, body?: any) {

        return this.store.select(selectSession).pipe(
            take(1),
            switchMap(session => {
                const request: AjaxConfig = {
                    method,
                    url,
                    body,
                    headers: {
                        ...headers,
                        'tenant-id': tenant,
                        'msp-id': session.session ? session.session.msp_id : undefined
                    }
                };
                return ajax(request);
            }),
            HandleAjaxResponse<T>(),
            catchError(HandleAjaxError)
        );
    }
}
