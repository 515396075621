import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, distinct, last, map, mergeMap } from 'rxjs/operators';
import { MfaOptions, MsolUser, NewMsolUser } from 'src/app/interfaces/powershell/msol/user.interface';
import { retry } from 'src/app/pipes/retry.pipe';
import { ChangesService } from 'src/app/services/changes.service';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';

import { storeChangesToDB } from '../../../octiga/changes/actions';
import { deleteCasMailbox } from '../../exo/cas-mailbox/actions';
import { deleteExoMailbox } from '../../exo/mailbox/actions';
import { deleteExoUser } from '../../exo/user/actions';
import * as MsolActions from './actions';
import { deleteMsolUser } from './actions';

function filterUsers(users: MsolUser[]): MsolUser[] {
    return users.filter(user => !user.UserPrincipalName.startsWith('octiga_service_user_'));
}

@Injectable()
export class MsolUserEffects {

    loadUsers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MsolActions.loadMsolUsers),
            distinct(action => action._tenant),
            mergeMap((action) => this.getMsolUsers(action._tenant)
                .pipe(
                    retry(3000, 3, 'msol timeout'),
                    last(),
                    map((data: MsolUser[]) => MsolActions.loadMsolUsersSuccess({ _tenant: action._tenant, data: filterUsers(data) })),
                    catchError((error) => of(MsolActions.loadUsersFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    createUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MsolActions.createUser),
            mergeMap((action) => {
                return this.createUser(action._tenant, action.user)
                    .pipe(
                        concatMap((msolUser) => {
                            const params = { user: action.user.UserPrincipalName, fields: { operation: 'create' } };
                            const item = this.changesService.formatChangesObjectToDB(params, 'user');
                            return [
                                // TODO: update exoUser and MsolRole?
                                // ISSUE: msolUser empty [] response
                                // MsolActions.addMsolUser({ msolUser }),
                                // ExoActions.loadExoUsers(),
                                storeChangesToDB({ _tenant: action._tenant, item }),
                                MsolActions.createUserSuccess({ _tenant: action._tenant, user: action.user })
                            ];
                        }),
                        catchError((error) => of(MsolActions.createUserFailure({ _tenant: action._tenant, error })))
                    );
            })
        )
    );

    updateUserMFA$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MsolActions.updateUserMFA),
            mergeMap((action) => this.updateUserMFA(action._tenant, action.upn, action.opts)
                .pipe(
                    concatMap(data => {

                        return [
                            MsolActions.updateUserMFASuccess({
                                _tenant: action._tenant,
                                msolUser: {
                                    id: action.upn,
                                    changes: {
                                        StrongAuthenticationRequirements: action.opts.mfaEnabled ? ['Microsoft.Online.Administration.StrongAuthenticationRequirement'] : []
                                    }
                                }
                            }),
                            MsolActions._updateUserMFASuccess(action)
                        ];
                    }
                    ),
                    catchError((error) => of(MsolActions.updateUserMFAFailure({ _tenant: action._tenant, upn: action.upn, error })))
                ))

        )
    );

    deleteUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MsolActions.deleteUser),
            mergeMap((action) => this.deleteUser(action._tenant, action.upn)
                .pipe(
                    concatMap((data) => {
                        const params = { user: action.upn, fields: { operation: 'delete' } };
                        const item = this.changesService.formatChangesObjectToDB(params, 'user');
                        return [
                            // TODO: deleteCasMailbox: convert upn to ExternalDirectoryObjectId
                            storeChangesToDB({ _tenant: action._tenant, item }),
                            deleteCasMailbox({ _tenant: action._tenant, id: action.upn.split('@')[0] }),
                            deleteExoMailbox({ _tenant: action._tenant, id: action.upn }),
                            deleteMsolUser({ _tenant: action._tenant, id: action.upn }),
                            deleteExoUser({ _tenant: action._tenant, id: action.upn }),
                            MsolActions.deleteUserSuccess({ _tenant: action._tenant, upn: action.upn })
                        ];
                    }),
                    catchError((error) => of(MsolActions.deleteUserFailure({ _tenant: action._tenant, error })))
                )
            )
        )
    );

    deleteUserLicense$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MsolActions.deleteUserLicense),
            mergeMap((action) => this.deleteUserLicense(action._tenant, action.upn)
                .pipe(
                    map(() => MsolActions.deleteUserLicenseSuccess(action)),
                    catchError((error) => of(MsolActions.deleteUserLicenseFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private changesService: ChangesService,
        private store: Store<any>
    ) { }

    getMsolUsers(tenant: string): Observable<Array<MsolUser>> {
        return this.ajax.get(tenant, '/api/microsoft/powershell/msol/user');
    }

    createUser(tenant: string, user: NewMsolUser): Observable<MsolUser> {
        const url = '/api/microsoft/powershell/msol/user';
        return this.ajax.post(tenant, url, user);
    }

    deleteUser(tenant: string, upn: string) {
        const url = `/api/microsoft/powershell/msol/user/${upn}`;
        return this.ajax.delete(tenant, url);
    }

    updateUserMFA(tenant: string, upn: string, opts: MfaOptions) {
        return this.ajax.put(tenant, `/api/microsoft/powershell/msol/user/${upn}`, opts);
    }

    deleteUserLicense(tenant: string, upn: string) {
        const url = `/api/microsoft/powershell/msol/user-license/${upn}`;
        return this.ajax.put(tenant, url);
    }

}
