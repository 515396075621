import { DeviceCompliancePolicyAssignment, Windows10CompliancePolicy } from '@microsoft/microsoft-graph-types-beta';
import { createAction, props } from '@ngrx/store';
import { PolicyAssignment } from './modet';

export const loadWindows10CompliancePolicies = createAction(
    '[Graph/Compliance Policies/Windows10] Load Windows 10 Compliance Policy',
    props<{ _tenant: string }>()
);


export const loadWindows10CompliancePoliciesSuccess = createAction(
    '[Graph/Compliance Policies/Windows10] Load Windows 10 Compliance Policies Success',
    props<{ _tenant: string, data: PolicyAssignment[] }>()
);

export const loadWindows10CompliancePoliciesFailure = createAction(
    '[Graph/Compliance Policies/Windows10] Load Windows 10 Compliance Policy Failure',
    props<{ _tenant: string, error: any }>()
);


export const createWidows10CompliancePolicy = createAction(
    '[Graph/Compliance policies/ windows 10] Create Windows 10 Compliance Policy ',
    props<{ _tenant: string, policy: Windows10CompliancePolicy, assignments?: DeviceCompliancePolicyAssignment[] }>()
);


export const createWidows10CompliancePolicySuccess = createAction(
    '[Graph/Compliance Policies/Windows10] Create Windows 10 Compliance Policy Success',
    props<{ _tenant: string, policy: PolicyAssignment }>()
);


export const createWidows10CompliancePolicyFailure = createAction(
    '[Graph/Compliance Policies/Windows 10] Create Windows 10 Compliance Policy Failure',
    props<{ _tenant: string, error: any }>()
);


export const updateWidows10CompliancePolicy = createAction(
    '[Graph/Compliance policies/ windows 10] Update Windows10 Compliance Policy ',
    props<{ _tenant: string, policy: any, assignments?: DeviceCompliancePolicyAssignment[] }>()
);


export const updateWidows10CompliancePolicySuccess = createAction(
    '[Graph/Compliance Policies/Windows10] Update Windows10 Compliance Policy Success',
    props<{ _tenant: string, policy: PolicyAssignment }>()
);


export const updateWidows10CompliancePolicyFailure = createAction(
    '[Graph/Compliance Policies/Windows10] Update Windows10 Compliance Policy Failure',
    props<{ _tenant: string, error: any }>()
);






export const deleteWidows10CompliancePolicy = createAction(
    '[Graph/Compliance policies/ windows 10] Delete Windows10 Compliance Policy ',
    props<{ _tenant: string, id: string }>()
);

export const deleteWidows10CompliancePolicySuccess = createAction(
    '[Graph/Compliance Policies/Windows10] Delete Windows10 Compliance Policy Success',
    props<{ _tenant: string, id: string }>()
);

export const deleteWidows10CompliancePolicyFailure = createAction(
    '[Graph/Compliance Policies/Windows10] Delete Windows10 Compliance Policy Failure',
    props<{ _tenant: string, error: any }>()
);
