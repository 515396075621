import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, switchMap, catchError, map, mergeMap, concatMap } from 'rxjs';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import { BlobService } from 'src/app/services/blob.service';
import { refreshTokenSuccess } from '../token/actions';
import * as actions from './actions';
import { Session } from './model';

const FIVE_MINUTES = 60 * 5 * 1000;

@Injectable()
export class SessionEffects {

    loadSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadSession),
            mergeMap(() => this.ajax.get<Session>('/api/microsoft/oauth/session')
                .pipe(
                    switchMap(session => {
                        const _actions = [
                            actions.loadSessionSuccess({ session }),
                            refreshTokenSuccess({ exp: (new Date()).getTime() + FIVE_MINUTES })
                        ];
                        if (session.msp_id && session.cache_enabled) {
                            return this.blobService.load(session.msp_id).pipe(map((data_actions) => [..._actions, ...data_actions]));
                        } else {
                            return of(_actions);
                        }
                    }),
                    concatMap(res => res),
                    catchError((error) => of(actions.loadSessionFailure({ error })))
                ))
        )
    );

    deleteSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteSession),
            mergeMap(({ microsoftSignout }) => this.ajax.delete('/api/microsoft/oauth/session')
                .pipe(
                    map(() => {
                        if (microsoftSignout) {
                            const redirect_uri = `${window.location.origin}/dashboard`;
                            const logout = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${redirect_uri}`;
                            window.location.href = logout;
                        } else {
                            const return_url = window.location.href;
                            this.router.navigate(['/auth/microsoft'], { queryParams: { return_url } });
                        }
                    }),
                    catchError((error) => of(actions.deleteSessionFailure({ error })))
                )
            )
        ),
    { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
        private router: Router,
        private blobService: BlobService
    ) { }
}
