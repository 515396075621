import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MsolUser } from 'src/app/interfaces/powershell/msol/user.interface';
import { initialStatus, Status } from '../../../../status.interface';
import * as MsolUserActions from './actions';

export const featureKey = 'msolUser';

export interface State extends EntityState<MsolUser>, Status { }

export const adapter: EntityAdapter<MsolUser> = createEntityAdapter<MsolUser>({
    selectId: (user) => user.UserPrincipalName
});

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,

    on(MsolUserActions.loadMsolUsersSuccess,
        (state, { data }) => adapter.setAll(data, { ...state, loaded: true })
    ),
    on(MsolUserActions.loadUsersFailure,
        (state, { error }) => ({ ...state, loaded: false, error })
    ),
    on(MsolUserActions.updateUserMFASuccess,
        (state, action) => adapter.updateOne(action.msolUser, state)
    ),
    on(MsolUserActions.updateMsolUser,
        (state, action) => adapter.updateOne(action.msolUser, state)
    ),
    on(MsolUserActions.deleteMsolUser,
        (state, { id }) => adapter.removeOne(id, state)
    )
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();
