import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, last, map, mergeMap } from 'rxjs/operators';
import { Role } from 'src/app/interfaces/powershell/msol/role.interface';
import { retry } from 'src/app/pipes/retry.pipe';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as MsolRoleActions from 'src/app/stores/client/powershell/msol/role/actions';

@Injectable()
export class RoleEffects {

    loadRole$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MsolRoleActions.loadRoles),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.getMsolRole(_tenant)
                .pipe(
                    retry(3000, 3, 'msol timeout'),
                    last(),
                    map((data: Role[]) => MsolRoleActions.loadRolesSuccess({ _tenant, msolRoles: data })),
                    catchError((error) => of(MsolRoleActions.loadRolesFailure({ _tenant, error })))
                ))
        )
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

    getMsolRole(tenant: string): Observable<Array<Role>> {
        const url = '/api/microsoft/powershell/msol/role';
        // url += `?SelectObject=DisplayName,EmailAddress,Role`;
        return this.ajax.get(tenant, url);
    }

}
