import { createReducer, on } from '@ngrx/store';
import * as actions from './actions';
import { Session } from './model';

export const featureKey = 'session';

export interface State {
    session: Session | null
    isAuthenticated: boolean
    loading: boolean
    loaded: boolean
    error: any
}

export const initialState: State = {
    session: null,
    isAuthenticated: false,
    loading: false,
    loaded: false,
    error: false
};

export const reducer = createReducer(
    initialState,
    on(actions.loadSession, (state) => ({ ...state, loading: true })),
    on(actions.loadSessionSuccess, (state, { session }) => ({ ...state, session, loading: false, loaded: true, isAuthenticated: true })),
    on(actions.loadSessionFailure, (state, { error }) => ({ ...state, error, loading: false, loaded: false, isAuthenticated: false })),
    on(actions.deleteSession, () => ({ ...initialState })),
    on(actions.deleteSessionSuccess, () => ({ ...initialState })),
    on(actions.deleteSessionFailure, (state, { error }) => ({ ...initialState, error })),
    on(actions.changeClient, (state, { clientId }) => ({ ...state, session: { ...state.session, clientId } }))
);

export const getSession = (state: State) => state;
