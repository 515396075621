<form class="example-form">
    <mat-form-field class="example-full-width">
        <mat-label>{{label}}</mat-label>
        <!-- <input type="text" placeholder="{{placeholder}}" aria-label="Number" matInput [formControl]="myControl"
            [matAutocomplete]="auto"> -->
        <mat-select [formControl]="myControl" multiple [(ngModel)]="selection">
                <mat-option *ngFor="let option of options" [value]="option">
                    {{option}}
                </mat-option>
        </mat-select>

    </mat-form-field>
</form>