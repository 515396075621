import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { interval, map, switchMap } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { refreshTokenSuccess } from 'src/app/stores/root/octiga/token/actions';

const FOUR_AND_A_HALF_MIN = 270 * 1000;

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor(private store: Store) { }

    public init() {
        interval(FOUR_AND_A_HALF_MIN)
            .pipe(
                switchMap(() => this.refresh())
            )
            .subscribe();
    }

    private refresh() {
        return ajax({ url: '/api/microsoft/oauth/token' })
            .pipe(
                map(({ response }: any) => {
                    this.store.dispatch(refreshTokenSuccess({ exp: response.exp * 1000 }));
                    return true;
                })
            );
    }

}
