import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { MsolUser, NewMsolUser } from 'src/app/interfaces/powershell/msol/user.interface';
import { MfaOptions } from 'src/app/interfaces/powershell/msol/user.interface';


export const loadMsolUsers = createAction(
    '[MSP Client/Msol User/API] Load Msol Users',
    props<{ _tenant: string }>()
);

export const loadMsolUsersSuccess = createAction(
    '[MSP Client/Msol User/API] Load Msol Users Success',
    props<{ _tenant: string, data: MsolUser[] }>()
);

export const loadUsersFailure = createAction(
    '[MSP Client/Msol User/API] Load Msol Users Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateMsolUser = createAction(
    '[MSP Client/Msol User/API] Update MsolUser',
    props<{ _tenant: string, msolUser: Update<MsolUser> }>()
);

export const deleteMsolUser = createAction(
    '[Root/Pwsh/Msol] Delete MsolUser',
    props<{ _tenant: string, id: string }>()
);

export const updateUserMFA = createAction(
    '[MSP Client/Msol User/API] Enable User MFA',
    props<{ _tenant: string, upn: string, opts: MfaOptions }>()
);

export const _updateUserMFASuccess = createAction(
    '[MSP Client/Msol User/API] _Enable User MFA Success',
    props<{ _tenant: string, upn: string, opts: MfaOptions }>()
);

export const updateUserMFASuccess = createAction(
    '[MSP Client/Msol User/API] Enable User MFA Success',
    props<{ _tenant: string, msolUser: Update<MsolUser> }>()
);

export const updateUserMFAFailure = createAction(
    '[MSP Client/Msol User/API] Enable User MFA Failure',
    props<{ _tenant: string, upn: string, error: any }>()
);

// Customize
export const createUser = createAction(
    '[Root/Pwsh/Msol] Create User',
    props<{ _tenant: string, user: NewMsolUser }>()
);

export const createUserSuccess = createAction(
    '[Root/Pwsh/Msol] Create User Success',
    props<{ _tenant: string, user: NewMsolUser }>()
);

export const createUserFailure = createAction(
    '[Root/Pwsh/Msol] Create User Failure',
    props<{ _tenant: string, error: any }>()
);

export const deleteUser = createAction(
    '[Root/Pwsh/Msol] Delete User',
    props<{ _tenant: string, upn: string }>()
);

export const deleteUserSuccess = createAction(
    '[Root/Pwsh/Msol] Delete User Success',
    props<{ _tenant: string, upn: string }>()
);

export const deleteUserFailure = createAction(
    '[Root/Pwsh/Msol] Delete User Failure',
    props<{ _tenant: string, error: any }>()
);

export const deleteUserLicense = createAction(
    '[Msol/User Admin Component] Delete User License',
    props<{ _tenant: string, upn: string }>()
);

export const deleteUserLicenseSuccess = createAction(
    '[Msol/User Admin Effect] Delete User License Success',
    props<{ _tenant: string, upn: string }>()
);

export const deleteUserLicenseFailure = createAction(
    '[Msol/User Admin Effect] Delete User License Failure',
    props<{ _tenant: string, error: any }>()
);
