import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from '../../../../status.interface';
import * as MsolRoleActions from '../role/actions';
import * as MsolRoleMemberActions from './actions';
import { MsolRole } from './model';

export const msolFeatureKey = 'msolRoles';

export interface State extends EntityState<MsolRole>, Status {
    // additional entities state properties
    fetched: boolean
}

export const adapter: EntityAdapter<MsolRole> = createEntityAdapter<MsolRole>({
    selectId: (role) => role.EmailAddress + '_' + role.Role
});

export const initialState: State = adapter.getInitialState({
    // additional entity state properties
    ...initialStatus,
    fetched: false
});


export const reducer = createReducer(
    initialState,
    
    on(MsolRoleActions.loadRolesSuccess,
        (state, action) => { state = { ...state, loaded: true, fetched: true }; return adapter.setAll(action.msolRoles, state); }
    ),
    on(MsolRoleActions.loadRolesFailure,
        (state, action) => ({ ...state, loaded: false, error: action.error })
    ),
    on(MsolRoleMemberActions.addMsolRole,
        (state, action) => adapter.addOne(action.msolRole, state)
    ),
    on(MsolRoleMemberActions.upsertMsolRole,
        (state, action) => adapter.upsertOne(action.msolRole, state)
    ),
    on(MsolRoleMemberActions.addMsolRoles,
        (state, action) => adapter.addMany(action.msolRoles, state)
    ),
    on(MsolRoleMemberActions.upsertMsolRoles,
        (state, action) => adapter.upsertMany(action.msolRoles, state)
    ),
    on(MsolRoleMemberActions.updateMsolRole,
        (state, action) => adapter.updateOne(action.msolRole, state)
    ),
    on(MsolRoleMemberActions.updateMsolRoles,
        (state, action) => adapter.updateMany(action.msolRoles, state)
    ),
    on(MsolRoleMemberActions.deleteMsolRole,
        (state, { id }) => adapter.removeOne(id, state)
    ),
    on(MsolRoleMemberActions.deleteMsolRoles,
        (state, action) => adapter.removeMany(action.ids, state)
    ),
    on(MsolRoleMemberActions.clearMsolRoles,
        state => adapter.removeAll(state)
    ),
    on(MsolRoleMemberActions.deleteAdminRoleSuccess,
        (state, action) => (state)),
    on(MsolRoleMemberActions.deleteAdminRoleFailure,
        (state, action) => ({ ...state, error: action.error })),
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();

export const selectStatus = (state: State) => ({ error: state.error, loaded: state.fetched });
