import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, mergeMap } from 'rxjs/operators';
import { ChangesService } from 'src/app/services/changes.service';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as ChangesActions from 'src/app/stores/client/octiga/changes/actions';
import * as MsolRoleMemberActions from './actions';

@Injectable()
export class RoleMemberEffects {

    //TODO: add msolRole?
    addRoleMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MsolRoleMemberActions.addAdminRole),
            mergeMap((action) => this.addRoleMember(action._tenant, action.roleName, action.upn)
                .pipe(
                    concatMap((data) => {
                        const params = { user: action.upn, fields: { operation: 'add', name: action.roleName } };
                        const item = this.changesService.formatChangesObjectToDB(params, 'admin-role');
                        return [
                            ChangesActions.storeChangesToDB({ _tenant: action._tenant, item }),
                            MsolRoleMemberActions.addAdminRoleSuccess({ _tenant: action._tenant, roleName: action.roleName, upn: action.upn })
                        ];
                    }),
                    catchError((error) => of(MsolRoleMemberActions.addAdminRoleFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    deleteRoleMember$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MsolRoleMemberActions.deleteAdminRole),
            mergeMap((action) => this.deleteRoleMember(action._tenant, action.roleName, action.upn)
                .pipe(
                    concatMap((data) => {
                        const params = { user: action.upn, fields: { operation: 'remove', name: action.roleName } };
                        const item = this.changesService.formatChangesObjectToDB(params, 'admin-role');
                        return [
                            ChangesActions.storeChangesToDB({ _tenant: action._tenant, item }),
                            MsolRoleMemberActions.deleteMsolRole({ _tenant: action._tenant, id: action.upn + '_' + action.roleName }),
                            MsolRoleMemberActions.deleteAdminRoleSuccess({ _tenant: action._tenant, roleName: action.roleName, upn: action.upn })
                        ];
                    }),
                    catchError((error) => of(MsolRoleMemberActions.deleteAdminRoleFailure({ _tenant: action._tenant, upn: action.upn, roleName: action.roleName, error })))
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService,
        private changesService: ChangesService
    ) { }

    addRoleMember(tenant: string, roleName: string, upn: string) {
        const url = `/api/microsoft/powershell/msol/role-member/${roleName}/${upn}`;
        return this.ajax.put(tenant, url);
    }

    deleteRoleMember(tenant: string, roleName: string, upn: string) {
        const url = `/api/microsoft/powershell/msol/role-member/${roleName}/${upn}`;
        return this.ajax.delete(tenant, url);
    }
}
