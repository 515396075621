import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { first, Observable, of, switchMap } from 'rxjs';
import { Tenant } from 'src/app/interfaces/tenant.interface';
import { client } from 'src/app/stores/client';
import { } from 'src/app/stores/client/octiga/tenant/actions';
import { CountriesDialogComponent } from './countries-dialog/countries-dialog.component';

function shouldOpenDialog(tenant: Tenant) {
    if (!tenant.allowlist) return true;
    if (tenant.allowlist.length === 0) return true;
    if (tenant.allowlist.some(a => a.added_during_registration)) return true;
    return false;
}

@Injectable({
    providedIn: 'root'
})
export class CountriesGuard implements CanActivate, CanActivateChild {

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check(route);
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.check(childRoute);
    }

    constructor(
        private store: Store,
        private dialog: MatDialog
    ) { }

    private check(route: ActivatedRouteSnapshot) {
        const tenant_id = route.params.tenant;
        // this.dispatcher(tenant_id)
        return this.store.select(client(tenant_id).octiga.tenant.data)
            .pipe(
                first(tenant => !!tenant),
                switchMap(tenant => shouldOpenDialog(tenant) ? this.showDialog(tenant) : of(true))
            );
    }

    private showDialog(tenant: Tenant) {
        const ref = this.dialog.open(CountriesDialogComponent, {
            width: '40rem',
            data: { tenant }
        });
        return ref.afterClosed();
    }

}
