import { Injectable } from '@angular/core';
import { DirectoryObject, DirectoryRole, Domain, Group, MailFolder, User } from '@microsoft/microsoft-graph-types-beta';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { EXOInboxRule } from '../interfaces/powershell/exo/inbox-rule.interface';
import { fetchGraphAuthenticationMethodsSuccess } from '../stores/client/graph/authentication/methods/actions';
import { AuthenticationMethodModel } from '../stores/client/graph/authentication/methods/model';
import { loadDirectoryRolesSuccess } from '../stores/client/graph/directoryRoles/actions';
import { directoryRoleMembersLoaded, loadDirectoryRoleMembersSuccess } from '../stores/client/graph/directoryRoles/members/actions';
import { DirectoryRoleMembersModel } from '../stores/client/graph/directoryRoles/members/model';
import { loadDomainsSuccess } from '../stores/client/graph/domains/actions';
import { loadGroupsSuccess } from '../stores/client/graph/group/actions';
import { loadMembersSuccess, membersLoaded } from '../stores/client/graph/group/members/actions';
import { fetchExoInboxRuleSuccess, fetchGraphInboxRulesSuccess } from '../stores/client/graph/inbox-rules/actions';
import { InboxRuleModel } from '../stores/client/graph/inbox-rules/model';
import { loadGraphUsersSuccess } from '../stores/client/graph/user/user.actions';
import { fetchCasMailboxesSuccess } from '../stores/client/powershell/exo/cas-mailbox/actions';
import { CasMailbox } from '../stores/client/powershell/exo/cas-mailbox/model';
import { loadMailboxesSuccess } from '../stores/client/powershell/exo/mailbox/actions';
import { Mailbox } from '../stores/client/powershell/exo/mailbox/model';
import { MailboxFolderService } from './mailbox-folder.service';

interface Blob {
    authMethods: AuthenticationMethodModel[]
    directoryRoleMembers: DirectoryRoleMembersModel[]
    directoryRoles: DirectoryRole[]
    domains: Domain[]
    groups: Group[]
    groupMembers: {
        [key: string]: DirectoryObject[]
    }
    mailboxes: Mailbox[]
    casMailboxes: CasMailbox[]
    messageRules: InboxRuleModel[]
    exoMessageRules: {
        user_id: string
        rules: EXOInboxRule[]
    }[]
    users: User[]
    mailFolders: {
        user_id: string
        folder_id: string
        folder: MailFolder
    }[]
}

@Injectable({
    providedIn: 'root'
})
export class BlobService {

    constructor(
        private mailFolderService: MailboxFolderService
    ) { }

    public load(msp_id: string) {
        return ajax.get<{ urls: { [key: string]: string } }>('/api/blob/urls', { 'msp-id': msp_id })
            .pipe(
                map(({ response }) => {
                    const urls: { [key: string]: Observable<Blob> } = {};
                    for (const [id, url] of Object.entries(response.urls)) {
                        urls[id] = ajax.get<any>(url, { 'msp-id': msp_id }).pipe(map(res => res.response), catchError(error => of(false)));
                    }
                    return urls;
                }),
                switchMap((urls) => forkJoin(urls).pipe(
                    map((res: { [key: string]: Blob }) => {

                        const actions = [];

                        for (const [_tenant, data] of Object.entries(res)) {

                            // console.log(_tenant, data)

                            const {
                                authMethods,
                                directoryRoleMembers,
                                directoryRoles,
                                domains,
                                groupMembers,
                                groups,
                                mailboxes,
                                casMailboxes,
                                messageRules,
                                exoMessageRules,
                                users,
                                mailFolders
                            } = data;

                            if (directoryRoleMembers) {
                                const entries = Object.entries(directoryRoleMembers);
                                for (const [id, item] of entries) {
                                    // note: these must be dispatched before loadDirectoryRolesSuccess to prevent their effect fetching
                                    actions.push(loadDirectoryRoleMembersSuccess({ _tenant, item }));
                                }
                                actions.push(directoryRoleMembersLoaded({ _tenant }));
                            }

                            if (users) {
                                // const filtered = users.filter(user => user.accountEnabled);
                                actions.push(loadGraphUsersSuccess({ _tenant, users }));
                            }
                            if (groups) actions.push(loadGroupsSuccess({ _tenant, groups }));
                            if (directoryRoles) actions.push(loadDirectoryRolesSuccess({ _tenant, roles: directoryRoles }));
                            if (domains) actions.push(loadDomainsSuccess({ _tenant, domains }));
                            if (mailboxes) actions.push(loadMailboxesSuccess({ _tenant, mailboxes }));
                            if (casMailboxes) actions.push(fetchCasMailboxesSuccess({ _tenant, data: casMailboxes }));
                            if (authMethods) actions.push(fetchGraphAuthenticationMethodsSuccess({ _tenant, data: authMethods }));

                            if (groupMembers) {
                                const entries = Object.entries(groupMembers);
                                for (const [id, members] of entries) {
                                    actions.push(loadMembersSuccess({ _tenant, id, members }));
                                }
                                actions.push(membersLoaded({ _tenant }));
                            }

                            if (messageRules) {
                                console.log(
                                    'message Rules found on Blob',
                                    _tenant,messageRules
                                );
                                actions.push(fetchGraphInboxRulesSuccess({ _tenant, rules: messageRules }));
                            }

                            if (exoMessageRules) {
                                for (const { user_id, rules } of exoMessageRules) {
                                    for (const rule of rules) {
                                        actions.push(fetchExoInboxRuleSuccess({ _tenant, user_id, rule }));
                                    }
                                }
                            }

                            if (mailFolders) {
                                for (const { user_id, folder_id, folder } of mailFolders) {
                                    this.mailFolderService.put(_tenant, user_id, folder_id, folder);
                                }
                            }

                        }
                        return actions;
                    })
                ))
            );
    }
}
