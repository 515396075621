import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as Windows10CompliancePolicyActions from './actions';
import { PolicyAssignment } from './modet';

export const featureKey = 'graphCompliancePolicyWindows10';

export interface State extends EntityState<PolicyAssignment>, Status { }

export const adapter: EntityAdapter<PolicyAssignment> = createEntityAdapter<PolicyAssignment>();

export const initialState: State = adapter.getInitialState({
    ...initialStatus
});

export const reducer = createReducer(
    initialState,
    // load
  
    on(Windows10CompliancePolicyActions.loadWindows10CompliancePoliciesSuccess,
        (state, action) =>{
            
            return adapter.upsertMany(action.data, ({ ...state, loaded: true, error: null }));}
    ),
    on(Windows10CompliancePolicyActions.loadWindows10CompliancePoliciesFailure,
        (state, { error }) => ({ ...state, error, loaded: false })
    ),

    // create
    on(Windows10CompliancePolicyActions.createWidows10CompliancePolicy,
        (state, action) => ({ ...state, updating: true, error: null }),
    ),
    on(Windows10CompliancePolicyActions.createWidows10CompliancePolicySuccess,
        (state, action) => adapter.upsertOne({id: action.policy.id, policy: action.policy}, ({ ...state, updating: false, error: null }))
    ),
    on(Windows10CompliancePolicyActions.createWidows10CompliancePolicyFailure,
        (state, { error }) => ({ ...state, error, updating: false })
    ),


    //update
    on(Windows10CompliancePolicyActions.updateWidows10CompliancePolicy,
        (state, action) => ({ ...state, updating: true, error: null}),
    ),
    on(Windows10CompliancePolicyActions.updateWidows10CompliancePolicySuccess, 
        (state, action) => adapter.upsertOne({id: action.policy.id, policy: action.policy}, ({ ...state, updating: false, error: null }))
    ),
    on(Windows10CompliancePolicyActions.updateWidows10CompliancePolicyFailure,
        (state, { error }) => ({ ...state, error, updating: false })
    ),

    
    // delete
    on(Windows10CompliancePolicyActions.deleteWidows10CompliancePolicy,
        (state, action) => ({ ...state, updating: true, error: null}),
    ),

    on(Windows10CompliancePolicyActions.deleteWidows10CompliancePolicySuccess, // TODO need chage here
        (state, action) => adapter.removeOne(action.id, ({ ...state, updating: false, error: null}))
    ),

    on(Windows10CompliancePolicyActions.deleteWidows10CompliancePolicyFailure,
        (state, { error }) => ({ ...state, error })
    )
);

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors();
