import { createReducer, on } from '@ngrx/store';
import { initialStatus, Status } from 'src/app/stores/status.interface';
import * as actions from './actions';

export const featureKey = 'token';

export interface State extends Status {
    exp: number
}

export const initialState: State = {
    ...initialStatus,
    exp: 0
};

export const reducer = createReducer(
    initialState,
    on(actions.refreshTokenSuccess, (state, { exp }) => ({ ...state, loading: false, loaded: true, exp })),
);

export const getTokenExpiry = (state: State) => state.exp;
