import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { Observable, OperatorFunction, catchError, delay, tap, throwError } from 'rxjs';
import { loadAction_start, loadAction_stop } from '../loader/store/loader.actions';
import { LoaderState } from '../loader/store/loader.reducer';
import { ActionBaseProps } from './actions-base';

/**
 * EffectsBase extends ngrx functions commonly used in an effects class.  It enforces and
 * facilitates development of common patterns.  Ngrx and data-flow are the backbone of an application, so
 * cross-cutting functionality is build directly off of this backbone.  EffectsBase is opinionated,
 * but is opt-in and can be ignored wholey or partially without issue.
 */
export abstract class EffectsBase {
    readonly DEFAULT_DELAY_INTERVAL = 500;
    readonly DEFAULT_ERROR_NOTICE_DURATION = 5000;
    readonly DEFAULT_SUCCESS_NOTICE_DURATION = 3000;

    constructor(
        protected actions$: Actions,
        protected loaderStore: Store<LoaderState>,
        protected snackbar: MatSnackBar,
    ) {}

    /**
   * Overrides/wraps the ngrx createEffect function and adds loadingState management
   *
   * Restricts createEffect to one input action, rather than the ngrx native 1+.  This is intentional
   * to enforce a pattern of one action in, 1+ actions out.
   * @param action The action to be watched for in Actions$ global ngrx stream
   * @param actionFnc The operator which wraps the effect's logic.  Often a high-order map
   * like mergeMap/switchMap/exhaustMap, or occassionaly wrapped in the ngrx pipe fnc
   * @param dispatch Boolean to determine if ngrx expects actions to be returned from the actionFnc
   * @returns 1 action, or an array of actions

   * Annotated example of use in an effect class
    ```typescript
    fetchMspAccessGroups$ = this.createEffect(
            fetchMspAccessGroups, //<<< 'action' param
            exhaustMap(() => //<<< 'actionFnc param.  The ngrx operator and internal piped stream
                this.mspAccessGroupsService.fetchAccessGroups().pipe(
                    switchMap((mspAccessGroups) => [
                        fetchMspAccessGroups_success({ accessGroups: mspAccessGroups }),
                    ]),
                    this.catchError('Error fetching access groups', fetchMspAccessGroups)
                )
            )
    );
    ```
   */
    createEffect<T extends ActionCreator>(
        action: T,
        actionFnc: OperatorFunction<Parameters<T>[0], Action>,
        dispatch: boolean = true
    ): Observable<Action> {
        return createEffect(
            () =>
                this.actions$.pipe(ofType(action), (source$: Observable<T>) =>
                    source$.pipe(
                        tap(() => this.loaderStore.dispatch(loadAction_start({ actionType: action.type }))),
                        actionFnc,
                        delay(this.DEFAULT_DELAY_INTERVAL),
                        tap(() => this.loaderStore.dispatch(loadAction_stop({ actionType: action.type })))
                    )
                ),
            { dispatch });
    }

    catchError(
        message?: string,
        stopAction?: Action,
        failFnc?: ActionBaseProps['failFnc']
    ): OperatorFunction<Action, Action> {
        return catchError((err) => {
            if (failFnc) failFnc(err);

            this.notify(message, 'snackbar-error');

            this.loaderStore.dispatch(loadAction_stop({ actionType: stopAction.type }));

            return throwError(() => err);
        });
    }

    success(
        message?: string,
        successFnc?: ActionBaseProps['successFnc']
    ): OperatorFunction<Action, Action> {
        return tap(() => {
            if (successFnc) successFnc();
            this.notify(message, 'snackbar-success');
        });
    }

    notify(message?: string, cssClass?: string ): void {
        if (!message) return; //==>

        this.snackbar.open(message, 'close', {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: [cssClass ?? 'snackbar-success'],
            duration: this.DEFAULT_SUCCESS_NOTICE_DURATION,
        });
    }
}

