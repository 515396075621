import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { MsolRole } from './model';

export const addMsolRole = createAction(
    '[Pwsh/Msol] Add MsolRole',
    props<{ _tenant: string, msolRole: MsolRole }>()
);

export const upsertMsolRole = createAction(
    '[Pwsh/Msol] Upsert MsolRole',
    props<{ _tenant: string, msolRole: MsolRole }>()
);

export const addMsolRoles = createAction(
    '[Pwsh/Msol] Add MsolRoles',
    props<{ _tenant: string, msolRoles: MsolRole[] }>()
);

export const upsertMsolRoles = createAction(
    '[Pwsh/Msol] Upsert MsolRoles',
    props<{ _tenant: string, msolRoles: MsolRole[] }>()
);

export const updateMsolRole = createAction(
    '[Pwsh/Msol] Update MsolRole',
    props<{ _tenant: string, msolRole: Update<MsolRole> }>()
);

export const updateMsolRoles = createAction(
    '[Pwsh/Msol] Update MsolRoles',
    props<{ _tenant: string, msolRoles: Update<MsolRole>[] }>()
);

export const deleteMsolRole = createAction(
    '[Pwsh/Msol] Delete MsolRole',
    props<{ _tenant: string, id: string }>()
);

export const deleteMsolRoles = createAction(
    '[Pwsh/Msol] Delete MsolRoles',
    props<{ _tenant: string, ids: string[] }>()
);

export const clearMsolRoles = createAction(
    '[Pwsh/Msol] Clear MsolRoles',
    props<{ _tenant: string }>()
);

// Customize - Mircosoft calls
export const deleteAdminRole = createAction(
    '[Pwsh/Msol] Delete Admin Role',
    props<{ _tenant: string, roleName: string, upn: string }>()
);

export const deleteAdminRoleSuccess = createAction(
    '[Pwsh/Msol] Delete Admin Role Success',
    props<{ _tenant: string, roleName: string, upn: string }>()
);

export const deleteAdminRoleFailure = createAction(
    '[Pwsh/Msol] Delete Admin Role Failure',
    props<{ _tenant: string, roleName: string, upn: string, error: any }>()
);

export const addAdminRole = createAction(
    '[Pwsh/Msol] Add Admin Role',
    props<{ _tenant: string, roleName: string, upn: string }>()
);

export const addAdminRoleSuccess = createAction(
    '[Pwsh/Msol] Add Admin Role Success',
    props<{ _tenant: string, roleName: string, upn: string }>()
);

export const addAdminRoleFailure = createAction(
    '[Pwsh/Msol] Add Admin Role Failure',
    props<{ _tenant: string, error: any }>()
);
