import { createAction, props } from '@ngrx/store';
import { AllowList, Tenant } from 'src/app/interfaces/tenant.interface';
import { TenantNotificationSetting } from './model';

export const loadTenant = createAction(
    '[MSP/Tenant] Load Tenant',
    props<{ _tenant: string }>()
);

export const loadTenantsSuccess = createAction(
    '[MSP/Tenant] Load Tenant Success',
    props<{ _tenant: string, data: Tenant }>()
);

export const loadTenantFailure = createAction(
    '[MSP/Tenant] Load Tenant Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateTenantNotificationSetting = createAction(
    '[Root/Tenant] Update Tenant Notification Setting',
    props<{ _tenant: string, data: Array<TenantNotificationSetting> }>()
);

export const updateTenantNotificationSettingSuccess = createAction(
    '[Root/Tenant] Update Tenant Notification Setting Success',
    props<{ _tenant: string, data: Tenant }>()
);

export const updateTenantNotificationSettingFailure = createAction(
    '[Root/Tenant] Update Tenant Notification Setting Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateDomainName = createAction(
    '[Root/Tenant] Update Domain Name',
    props<{ _tenant: string, name: string }>()
);

export const updateDomainNameSuccess = createAction(
    '[Root/Tenant] Update Domain Name Success',
    props<{ _tenant: string, data: Tenant }>()
);

export const updateDomainNameFailure = createAction(
    '[Root/Tenant] Update Domain Name Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateContactEmail = createAction(
    '[Root/Tenant] Update Contact Email',
    props<{ _tenant: string, email: string }>()
);

export const updateContactEmailSuccess = createAction(
    '[Root/Tenant] Update Contact Email Success',
    props<{ _tenant: string, data: Tenant }>()
);

export const updateContactEmailFailure = createAction(
    '[Root/Tenant] Update Contact Email Failure',
    props<{ _tenant: string, error: any }>()
);

export const updateAllowlist = createAction(
    '[Root/Tenant] Update Country Allowlist',
    props<{ _tenant: string, data: Array<AllowList> }>()
);

export const updateAllowlistSuccess = createAction(
    '[Root/Tenant] Update Country Allowlist Success',
    props<{ _tenant: string, data: Tenant }>()
);

export const updateAllowlistFailure = createAction(
    '[Root/Tenant] Update Country Allowlist Failure',
    props<{ _tenant: string, error: any }>()
);

export const deleteTenantRequest = createAction(
    '[Root/Tenant] Delete Tenant Request',
    props<{ _tenant: string }>()
);

export const deleteTenantRequestSuccess = createAction(
    '[Root/Tenant] Delete Tenant Request Success',
    props<{ _tenant: string, data: any }>()
);

export const deleteTenantRequestFailure = createAction(
    '[Root/Tenant] Delete Tenant Request Failure',
    props<{ _tenant: string, error: any }>()
);
