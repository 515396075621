import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ajax, AjaxConfig } from 'rxjs/ajax';
import { catchError, switchMap, take } from 'rxjs/operators';
import { selectSession } from '../../stores/root.store';
import { HandleAjaxError, HandleAjaxResponse } from './util';
import { Observable } from 'rxjs';

const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
};

@Injectable({
    providedIn: 'root'
})
export class MspAjaxService {

    constructor(
        private store: Store
    ) { }

    public get<T = any>(url: string): Observable<T> {
        return this.ajax<T>('GET', url);
    }

    public put<T = any, P = any>(url: string, body?: P): Observable<T> {
        return this.ajax<T>('PUT', url, body);
    }

    public post<T = any, P = any>(url: string, body?: P): Observable<T> {
        return this.ajax<T>('POST', url, body);
    }

    public patch<T = any, P = any>(url: string, body?: P): Observable<T> {
        return this.ajax<T>('PATCH', url, body);
    }

    public delete<T = any>(url: string): Observable<T> {
        return this.ajax<T>('DELETE', url);
    }

    private ajax<T, P = any>(method: string, url: string, body?: P): Observable<T> {

        return this.store.select(selectSession).pipe(
            take(1),
            switchMap(session => {
                const request: AjaxConfig = {
                    method,
                    url,
                    body,
                    headers: {
                        ...headers,
                        'msp-id': session.session ? session.session.msp_id : undefined
                    }
                };
                return ajax(request);
            }),
            HandleAjaxResponse<T>(),
            catchError(HandleAjaxError)
        );

    }

}
