<h2 mat-dialog-title>First Time Setup</h2>

<mat-dialog-content class="mat-typography">
    <h3>Configure Allowed Countries</h3>

    <p>The Allowed Countries list is used to help identify suspicious activity. It is important to correctly configure
        this list to avoid false-postive alerts. You can add or change the allowed countries using the drop-down below.
        You can also change these at a later time, if required.
    </p>

    <p *ngIf="pre_selected_countries">
        We've detected and pre-selected the following countries from the client tenant:
        <b>{{pre_selected_countries}}</b>
    </p>

    <div class="select">
        <app-multiple-selection-list label="Select Allowed Countries" [options]="countries"
            [selection]="selected_countries" (selected)="countriesChanged($event)">
        </app-multiple-selection-list>
    </div>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">Cancel</button>
    <button mat-raised-button color="primary" cdkFocusInitial (click)="save()">Save & Continue</button>
</mat-dialog-actions>
