import { createAction, props } from '@ngrx/store';

import { CwmConfig, CwmConnection } from '../integrations/cwm/model';
import { DattoConfig } from '../integrations/datto/model';
import { HaloConfig, HaloConnection } from './../integrations/halo/model';
import { Msp, MspClient } from './model';

export const loadMsp = createAction(
    '[Msp/API] Load Msp',
    props<{ msp_id: string }>()
);

export const loadMspSuccess = createAction(
    '[Msp/API] Load Msp Success',
    props<{ data: Msp }>()
);

export const loadMspFailure = createAction(
    '[Msp/API] Load Msp Failure',
    props<{ error: any }>()
);

export const updateMspTenants = createAction(
    '[Msp/API] Update Msp Tenants',
    props<{ msp_id: string, tenants: MspClient[] }>()
);

export const updateMspTenantsSuccess = createAction(
    '[Msp/API] Update Msp Tenants Success',
    props<{ tenants: MspClient[] }>()
);

export const updateMspTenantsFailure = createAction(
    '[Msp/API] Update Msp Tenants Failure',
    props<{ error: any }>()
);

export const deleteMspTenant = createAction(
    '[Msp/API] Delete Msp Tenant',
    props<{ tenant: string, actor: string }>()
);

export const deleteMspTenantSuccess = createAction(
    '[Msp/API] Delete Msp Tenant Success',
    props<{ tenant: string }>()
);

export const deleteMspTenantFailure = createAction(
    '[Msp/API] Delete Msp Tenant Failure',
    props<{ error: any }>()
);

export const sendMspLockoutEmail = createAction(
    '[Msp/API] Send Msp Lockout Email',
    props<{ msp_id: string, name: string, upn: string, country_code: string }>()
);

export const sendMspLockoutEmailSuccess = createAction(
    '[Msp/API] Send Msp Lockout Email Success',
    props<{ name: string, upn: string, country_code: string }>()
);

export const sendMspLockoutEmailFailure = createAction(
    '[Msp/API] Send Msp Lockout Email Failure',
    props<{ error: any }>()
);

export const sendMspUpgradeRequestEmail = createAction(
    '[Msp/API] Send Msp Upgrade Request Email',
    props<{ msp_id: string, name: string, upn: string, country_code: string }>()
);

export const sendMspUpgradeRequestEmailSuccess = createAction(
    '[Msp/API] Send Msp Upgrade Request Email Success',
    props<{ name: string, upn: string, country_code: string }>()
);

export const sendMspUpgradeRequestEmailFailure = createAction(
    '[Msp/API] Send Msp Upgrade Request Email Failure',
    props<{ error: any }>()
);

export const updateMsp = createAction(
    '[Msp] Update Msp',
    props<{ data: Partial<Msp> }>()
);


export const updateMspSuccess = createAction(
    '[Msp] Update Msp Success',
    props<{ data: Partial<Msp> }>()
);


export const updateMspFailure = createAction(
    '[Msp/API] Update Msp Failure',
    props<{ error: any }>()
);

export const updateMspCwmConnection = createAction(
    '[Msp/API] Update Msp Cwm Connection',
    props<{ msp_id: string, data: CwmConnection }>()
);

export const updateMspCwmConnectionSuccess = createAction(
    '[Msp/API] Update Msp Cwm Connection Success',
);

export const updateMspCwmConnectionFailure = createAction(
    '[Msp/API] Update Msp Cwm Connection Failure',
    props<{ error: string }>()
);

export const updateMspDattoConnection = createAction(
    '[Msp/API] Update Msp Datto Connection',
    props<{ msp_id: string, data: HaloConnection }>()
);

export const updateMspDattoConnectionSuccess = createAction(
    '[Msp/API] Update Msp Datto Connection Success',
);

export const updateMspDattoConnectionFailure = createAction(
    '[Msp/API] Update Msp Datto Connection Failure',
    props<{ error: string }>()
);


export const updateMspHaloConnection = createAction(
    '[Msp/API] Update Msp Halo Connection',
    props<{ msp_id: string, data: HaloConnection }>()
);

export const updateMspHaloConnectionSuccess = createAction(
    '[Msp/API] Update Msp Halo Connection Success',
);

export const updateMspHaloConnectionFailure = createAction(
    '[Msp/API] Update Msp Halo Connection Failure',
    props<{ error: any }>()
);

export const deleteMspHaloConnection = createAction(
    '[Msp/API] Delete Msp Halo Connection',
    props<{ msp_id: string }>()
);

export const deleteMspHaloConnectionSuccess = createAction(
    '[Msp/API] Delete Msp Halo Connection Success',
    props<{ deleted: boolean }>()
);

export const deleteMspHaloConnectionFailure = createAction(
    '[Msp/API] Delete Msp Halo Connection Failure',
    props<{ error: any }>()
);

export const deleteMspDattoConnection = createAction(
    '[Msp/API] Delete Msp Datto Connection',
    props<{ msp_id: string }>()
);

export const deleteMspDattoConnectionSuccess = createAction(
    '[Msp/API] Delete Msp Datto Connection Success',
    props<{ deleted: boolean }>()
);

export const deleteMspDattoConnectionFailure = createAction(
    '[Msp/API] Delete Msp Datto Connection Failure',
    props<{ error: any }>()
);

export const deleteMspCwmConnection = createAction(
    '[Msp/API] Delete Msp Cwm Connection',
    props<{ msp_id: string }>()
);

export const deleteMspCwmConnectionSuccess = createAction(
    '[Msp/API] Delete Msp Cwm Connection Success',
    props<{ deleted: boolean }>()
);

export const deleteMspCwmConnectionFailure = createAction(
    '[Msp/API] Delete Msp Cwm Connection Failure',
    props<{ error: any }>()
);

// datto config
export const updateMspDattoConfig = createAction(
    '[Msp/Datto] Update Msp Datto Config',
    props<{ msp_id: string, data: DattoConfig }>()
);

export const updateMspDattoConfigSuccess = createAction(
    '[Msp/Datto] Update Msp Datto  Config Success',
    props<{ data: DattoConfig }>()
);

export const updateMspDattoConfigFailure = createAction(
    '[Msp/Datto] Update Msp Datto Config Failure',
    props<{ error: any }>()
);

// halo config
export const updateMspHaloConfig = createAction(
    '[Msp/Halo] Update Msp Halo Config',
    props<{ msp_id: string, data: HaloConfig }>()
);

export const updateMspHaloConfigSuccess = createAction(
    '[Msp/Halo] Update Msp Halo  Config Success',
    props<{ data: any }>()
);

export const updateMspHaloConfigFailure = createAction(
    '[Msp/Halo] Update Msp Halo Config Failure',
    props<{ error: any }>()
);

// connectwise config
export const updateMspCwmConfig = createAction(
    '[Msp/Cwm] Update Msp Cwm Config',
    props<{ msp_id: string, data: CwmConfig }>()
);

export const updateMspCwmConfigSuccess = createAction(
    '[Msp/Cwm] Update Msp Cwm  Config Success',
    props<{ data: CwmConfig }>()
);

export const updateMspCwmConfigFailure = createAction(
    '[Msp/Cwm] Update Msp Cwm Config Failure',
    props<{ error: any }>()
);
