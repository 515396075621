import { Injectable } from '@angular/core';
import { Windows10CompliancePolicy, DeviceCompliancePolicyAssignment } from '@microsoft/microsoft-graph-types-beta';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { catchError, concatMap, distinct, last, map, mergeMap, switchMap } from 'rxjs/operators';
import { retry } from 'src/app/pipes/retry.pipe';
import * as actions from './actions';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { PolicyAssignment } from './modet';
// DeviceManagementConfiguration.ReadWrite.All 
@Injectable()
export class Windows10CompliancePolicyEffect {

    loadWindows10CompliancePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadWindows10CompliancePolicies),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    retry(3000, 3, 'Windows 10 Compliance Policy policy timeout'),
                    last(),
                    map((data: any) => {
                        return actions.loadWindows10CompliancePoliciesSuccess({ _tenant, data });
                    }),
                    catchError((error) => of(actions.loadWindows10CompliancePoliciesFailure({ _tenant, error }))),
                )
            )
        )
    );


    get(_tenant: string) {
        // const getAssignments = (policy) => {

        //     return this.ajax.get<{ value: DeviceCompliancePolicyAssignment[] }>(_tenant, `/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/${policy.id}/assignments`)
        //         .pipe(map(res => ({ id: policy.id, policy, assignments: res.value })))
        // }


        // const policies$ = this.ajax.get<{ value: Array<Windows10CompliancePolicy> }>(_tenant, '/api/microsoft/graph/deviceManagement/deviceCompliancePolicies');

        // const policy_assignments$ = policies$.pipe(concatMap(res => {
        //     return combineLatest(res.value.map(item => getAssignments(item)))
        // }))

        // return policy_assignments$;



        const policies$ =
            this.ajax.get<{ value: Array<Windows10CompliancePolicy> }>(_tenant, '/api/microsoft/graph/deviceManagement/deviceCompliancePolicies')
                .pipe(map(res => res.value), map(res => res.map(policy => ({ id: policy.id, policy }))));
        
        return policies$;
    }

    createWindows10CompliancePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createWidows10CompliancePolicy),
            mergeMap(({ _tenant, policy, assignments }) => this.createCompliancePolicy(_tenant, policy)
                .pipe(
                    map((policy: PolicyAssignment) => actions.createWidows10CompliancePolicySuccess({ _tenant, policy })),
                    catchError((error) => of(actions.createWidows10CompliancePolicyFailure({ _tenant, error }))),
                )
            )
        )
    );

    create(_tenant: string, policy: Partial<Windows10CompliancePolicy>, assignments: DeviceCompliancePolicyAssignment[]) {
        return this.createCompliancePolicy(_tenant, policy)
            .pipe(switchMap(policy => this.createPolicyAssignments(_tenant, policy.id, assignments)));
    }

    createCompliancePolicy(_tenant: string, policy: Partial<Windows10CompliancePolicy>) {
        return this.ajax.post<Windows10CompliancePolicy>(_tenant, '/api/microsoft/graph/deviceManagement/deviceCompliancePolicies', policy);
    }


    createPolicyAssignments(_tenant: string, policyId: string, assignments: DeviceCompliancePolicyAssignment[]) {
        return this.ajax.post<Windows10CompliancePolicy>(_tenant, `/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/${policyId}/assignments`);
    }

    updateWindows10CompliancePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateWidows10CompliancePolicy),
            mergeMap(({ _tenant, policy, assignments }) => this.update(_tenant, policy, assignments)

                .pipe(
                    // retry(3000, 3, 'Windows 10 compliance policy timeout'),
                    last(),
                    map((res) => actions.updateWidows10CompliancePolicySuccess({ _tenant, policy: { ...policy } })),
                    catchError((error) => of(actions.updateWidows10CompliancePolicyFailure({ _tenant, error }))),
                )
            )
        )
    );


    update(_tenant: string, policy: Windows10CompliancePolicy, assignments: DeviceCompliancePolicyAssignment[]) {
        const request$ = this.ajax.patch<Windows10CompliancePolicy>(_tenant, '/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/' + policy.id, policy);
        // const assignments$ = this.updatePolicyAssignments(_tenant, policy.id, assignments)

        return request$
            .pipe(concatMap(res => this.ajax.get(_tenant, `/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/${policy.id}`)));
    }

    updatePolicyAssignments(_tenant: string, policyId: string, assignments: DeviceCompliancePolicyAssignment[]) {
        return combineLatest([...assignments.map(res => this.ajax.post<Windows10CompliancePolicy>(_tenant, `/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/${policyId}/assignments`, res))]);

    }



    deletePolicyAssignments(_tenant: string, policyId: string, assignments: DeviceCompliancePolicyAssignment[]) {
        return this.ajax.delete<Windows10CompliancePolicy>(_tenant, `/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/${policyId}/assignments/${assignments[0].id}`);
    }

    deleteWindows10CompliancePolicy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteWidows10CompliancePolicy),
            mergeMap(({ _tenant, id }) => this.ajax.delete<string>(_tenant, '/api/microsoft/graph/deviceManagement/deviceCompliancePolicies/' + id)
                .pipe(
                    retry(3000, 3, 'Windows 10 compliance policy timeout'),
                    last(),
                    map(() => actions.deleteWidows10CompliancePolicySuccess({ _tenant, id })),
                    catchError((error) => of(actions.deleteWidows10CompliancePolicyFailure({ _tenant, error }))),
                )
            )
        )
    );

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}
