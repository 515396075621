<!-- form  -->
<div class="container">
    <div class="validator">
        <h2>
            Domains
        </h2>

        <div *ngFor="let domain of verified_domains">
            <mat-card>
                <div class="align-items-center header d-flex justify-content-between">
                    <h4>{{ domain.id }} <mat-icon matTooltip="Specifies the number of days before a user receives notification that their password will expire. If the property is not set, a default value of 14 days will be used.">info</mat-icon></h4>

                    <mat-icon color="success" *ngIf="!domain.isDeviated">done</mat-icon>

                    <mat-icon color="warn" class="warning-icon" *ngIf="domain.isDeviated">error</mat-icon>
                </div>

                <ul>
                    <li class="list-unstyled">
                        <span> Notification Days  <mat-icon matTooltip="Specifies the length of time that a password is valid before it must be changed. If the property is not set, a default value of 90 days will be used.">info</mat-icon> </span>

                        <span>
                            {{ domain?.passwordNotificationWindowInDays }}
                        </span>
                    </li>

                    <li class="list-unstyled">
                        <span> Validity Period  </span>

                        <span>
                            {{ domain.passwordValidityPeriodInDays }}
                        </span>
                    </li>
                </ul>
            </mat-card>
        </div>


        <ng-container *ngIf="unVerified_domains$ | async as unVerified_domains">
            <ng-container *ngIf="unVerified_domains.length > 0">
                <mat-divider></mat-divider>
                <h2>
                    UnVerified Domains
                </h2>
                <p>
                    These domains cannot be remediated because they are either incomplete or have not been verified.
                    Please go to the Azure portal to check these domains.
                </p>
                <div *ngFor="let domain of unVerified_domains">
                    <mat-card>
                        <div class="align-items-center header d-flex justify-content-between">
                            <h4>{{ domain.id }}</h4>
                        </div>

                        <mat-error> You have to verify this domain first.</mat-error>
                    </mat-card>
                </div>
            </ng-container>
        </ng-container>


        
        <ng-container *ngIf="nonRoot_domains$ | async as nonRoot_domains">
            <ng-container *ngIf="nonRoot_domains.length > 0">
                <mat-divider></mat-divider>
                <h2>
                    Non-root Domains
                </h2>

            

                <div >
                    <mat-card>
                        <mat-card-subtitle>
                            These domains are non-root domains and they adhere to their root domain configurations..
                        </mat-card-subtitle>
                        
                        <ul >
                            <li class="block" *ngFor="let domain of nonRoot_domains">{{ domain.id }}</li>
                        </ul>
                    </mat-card>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <form *ngIf="form" [formGroup]="form">
        <h2>Desired Value</h2>


        <div class="field boolean">
            <mat-form-field appearance="outline">
                <label>Notification Days</label>
                <input oninput="validity.valid||(value='');" min="0" max="MAX_INT" #passwordNotificationInput matInput type="number" formControlName="passwordNotification" />
                <mat-hint *ngIf="!passwordNotificationInput?.value" class="warning">If the property is not set, a default value of 14 days will be used.</mat-hint>
                <mat-hint *ngIf="passwordNotificationInput?.value == '0' " class="warning">Zero for no notification</mat-hint>
                <mat-error *ngIf="form.get('passwordNotification').errors?.max">
                    Maximum allowed input is {{MAX_INT}}.
                </mat-error>
                <mat-error *ngIf="form.get('passwordNotification').errors?.min">
                    Minimum allowed input is 0.
                </mat-error>
            </mat-form-field>
        </div>

        <div class="field boolean">
            <mat-form-field appearance="outline">
                <label>Validity Period </label>
                <input oninput="validity.valid||(value='');" max="MAX_INT" min="0" #validityInput matInput type="number" formControlName="validityPeriod" />

                <mat-hint *ngIf="!validityInput?.value" class="warning">If the property is not set, a default value of 90 days will be used.</mat-hint>

                <mat-hint *ngIf="validityInput?.value == '0'" class="warning">This will force all passwords to be
                    changed immediately.</mat-hint>

                <mat-hint *ngIf="+validityInput?.value < 90 &&  +validityInput?.value != 0" class="warning">Setting
                    short password validity periods can cause business disruption.</mat-hint>

                <mat-error *ngIf="form.get('validityPeriod').errors?.max">
                    Max allowed input is {{MAX_INT}}
                </mat-error>

                <mat-error *ngIf="form.get('validityPeriod').errors?.min">
                    Minimum allowed input is 0.
                </mat-error>
            </mat-form-field>

            <mat-slide-toggle *ngIf="!readOnly" matTooltip="Set validity period to the (Maximum) value"
                [checked]="form.get('validityPeriod').value === MAX_INT" (change)="passwordValidityToggle($event)">Set
                Maximum</mat-slide-toggle>
        </div>
    </form>
</div>