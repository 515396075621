import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { client } from 'src/app/stores/client';
import { MissingRoles } from 'src/app/utils/configured-azure-permissions';

@Component({
    selector: 'app-permission-dialog',
    templateUrl: './permission-dialog.component.html',
    styleUrls: ['./permission-dialog.component.scss'],
    animations: [
        trigger('showHide', [
            state('close', style({ height: '0px', overflow: 'hidden' })),
            state('open', style({ height: '*', overflow: 'hidden' })),
            transition('open <=> close', animate('600ms ease-in-out')),
        ])
    ]
})
export class PermissionDialogComponent implements OnInit {

    public addInfo: 'open' | 'close' = 'close';

    roles: string[] = [];
    missing_roles = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { link: string, tenant: string },
        private store: Store<any>
    ) { }

    ngOnInit(): void {
        this.store.select(client(this.data.tenant).octiga.status.roles.all)
            .pipe(take(1))
            .subscribe(roles => {
                this.missing_roles = MissingRoles(roles);
            });

    }

}
