import { combineLatest, filter } from 'rxjs';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { MailboxDelegate } from 'src/app/interfaces/powershell/exo/mailbox-delegate.interface';
import { retry } from 'src/app/pipes/retry.pipe';
import { ChangesService } from 'src/app/services/changes.service';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as ChangesActions from 'src/app/stores/client/octiga/changes/actions';
import * as ExoActions from './actions';

export interface DelegateBody {

    AddRecipientPermissionIdentities: Array<any>,
    AddMailboxPermissionIdentities: Array<any>,
    RemoveRecipientPermissionIdentities: Array<any>,
    RemoveMailboxPermissionIdentities: Array<any>

}

@Injectable()
export class MailboxDelegateEffects {
    getMailboxDelegates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.getMailboxDelegates),
            mergeMap((action) => this.getMailboxDelegates(action._tenant, action.Identity)
                .pipe(
                    retry(3000, 3, 'mobile delegated timeout'),
                    map((data: MailboxDelegate) => {
                        // TODO: Don't overwrite the original entities, should push to the current state
                        return ExoActions.getMailboxDelegatesSuccess({ _tenant: action._tenant, exoMailboxDelegates: { ...data, Identity: action.Identity } });
                    }),
                    catchError((error) => of(ExoActions.getMailboxDelegatesFailure({ _tenant: action._tenant, error })))
                ))
        )
    );

    getMailboxDelegates(_tenant: string, Identity: string) {
        const getMailboxPermissions$ = this.ajax.get(_tenant, `/api/microsoft/powershell/exo/mailbox-permission/${Identity}`).pipe(map(res => res.filter(re => re.User !== 'NT AUTHORITY\\SELF')));
        const getRecipientPermissions$ = this.ajax.get(_tenant, `/api/microsoft/powershell/exo/recipient-permission/${Identity}`).pipe(map(res => res.filter(re => re.Trustee !== 'NT AUTHORITY\\SELF')));

        return combineLatest([getMailboxPermissions$, getRecipientPermissions$]).pipe(map(([MailboxPermissions, RecipientPermissions]) => ({ MailboxPermissions, RecipientPermissions })));
    }

    updateMailboxDelegates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ExoActions.updateMailboxDelegates),
            mergeMap((action) => this.ajax.put(action._tenant, `/api/microsoft/powershell/exo/mailbox/${action.Identity}/delegates`, action.delegates)
                .pipe(
                    concatMap(() => {
                        const params = { user: action.Identity, fields: { operation: 'delegate', SendAs: action.delegates.AddRecipientPermissionIdentities, FullAccess: action.delegates.AddMailboxPermissionIdentities } };
                        const item = this.changesService.formatChangesObjectToDB(params, 'shared-mailbox');
                        return [
                            ChangesActions.storeChangesToDB({ _tenant: action._tenant, item }),
                            ExoActions.getMailboxDelegates({ _tenant: action._tenant, Identity: action.Identity }),
                            ExoActions.updateMailboxDelegatesSuccess({ _tenant: action._tenant, identity: action.Identity, delegates: action.delegates })
                        ];
                    }),
                    catchError((error) => of(ExoActions.updateMailboxDelegatesFailure({ _tenant: action._tenant, error: {message : error.message, trace: error.trace} })))
                ))
        )
    );

    updateMailboxDelegates(_tenant: string, Identity: string, delegates: DelegateBody) {
        const recipientPermissions = {
            AddRecipientPermissionIdentities: delegates.AddRecipientPermissionIdentities,
            RemoveRecipientPermissionIdentities: delegates.RemoveRecipientPermissionIdentities,
        };
        const mailboxPermissions = {
            RemoveMailboxPermissionIdentities: delegates.RemoveMailboxPermissionIdentities,
            AddMailboxPermissionIdentities: delegates.AddMailboxPermissionIdentities
        };

        const getMailboxPermissions$ = this.ajax.get(_tenant, `/api/microsoft/powershell/exo/mailbox-permission/${Identity}`);
        const getRecipientPermissions$ = this.ajax.get(_tenant, `/api/microsoft/powershell/exo/recipient-permission/${Identity}`);

        return combineLatest([getMailboxPermissions$, getRecipientPermissions$]).pipe(map(([MailboxPermissions, RecipientPermissions]) => ({ MailboxPermissions, RecipientPermissions })));

    }

    constructor(private actions$: Actions,
        private ajax: TenantAjaxService,
        private changesService: ChangesService) { }


}
