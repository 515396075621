import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, distinct, map } from 'rxjs/operators';
import { Tenant } from 'src/app/interfaces/tenant.interface';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as TenantActions from './actions';

@Injectable()
export class TenantEffects {

    loadTenant$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TenantActions.loadTenant),
            distinct(action => action._tenant),
            concatMap(({ _tenant }) => this.getTenant(_tenant)
                .pipe(
                    map((data: Tenant) => TenantActions.loadTenantsSuccess({ _tenant, data })),
                    catchError((error) => of(TenantActions.loadTenantFailure({ _tenant, error })))
                )
            )
        )
    );

    updateTenantNotificationSetting$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TenantActions.updateTenantNotificationSetting),
            concatMap(({ _tenant, data }) => this.ajax.put(_tenant, '/api/octiga/v2/tenant/notifications', data)
                .pipe(
                    concatMap((data) => [
                        TenantActions.updateTenantNotificationSettingSuccess({ _tenant, data }),
                    ]
                    ),
                    catchError((error) => of(TenantActions.updateTenantNotificationSettingFailure({ _tenant, error })))
                )
            )
        )
    );

    updateDomainName$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TenantActions.updateDomainName),
            concatMap(({ _tenant, name }) => {
                return this.ajax.put(_tenant, '/api/octiga/v2/tenant/name', { name })
                    .pipe(
                        concatMap(data => [
                            TenantActions.loadTenant({ _tenant }),
                            TenantActions.updateDomainNameSuccess({ _tenant, data })
                        ]),
                        catchError((error) => of(TenantActions.updateDomainNameFailure({ _tenant, error })))
                    );
            })
        )
    );

    updateContactEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TenantActions.updateContactEmail),
            concatMap(({ _tenant, email }) => {
                return this.ajax.put(_tenant, '/api/octiga/v2/tenant/contact-email', { 'contact_email': email })
                    .pipe(
                        map(data => TenantActions.updateContactEmailSuccess({ _tenant, data })),
                        catchError((error) => of(TenantActions.updateContactEmailFailure({ _tenant, error })))
                    );
            })
        )
    );

    updateAllowlist$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TenantActions.updateAllowlist),
            concatMap(({ _tenant, data }) => this.ajax.put(_tenant, '/api/octiga/v2/tenant/allowlist', data)
                .pipe(
                    concatMap((data) => [
                        TenantActions.updateAllowlistSuccess({ _tenant, data }),
                    ]
                    ),
                    catchError((error) => of(TenantActions.updateAllowlistFailure({ _tenant, error })))
                )
            )
        )
    );

    deleteTenantRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TenantActions.deleteTenantRequest),
            concatMap(({ _tenant }) => this.ajax.delete(_tenant, '/api/octiga/v2/tenant-request')
                .pipe(
                    map(data => TenantActions.deleteTenantRequestSuccess({ _tenant, data })),
                    catchError((error) => of(TenantActions.deleteTenantRequestFailure({ _tenant, error }))
                    )
                ))
        )
    );

    private getTenant(tenant: string): Observable<Tenant> {
        return this.ajax.get(tenant, '/api/octiga/v2/tenant');
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}
