import { createAction, props } from '@ngrx/store';
import { Msp } from './model';



export const loadSwayMspConfig = createAction(
    '[Msp/API] Load  Sway  Msp Config', props<{msp_id: string}>()
);

export const loadSwayMspConfigSuccess = createAction(
    '[Msp/API] Load Sway Msp Config Success',
    props<{ data: Msp[] }>()
);

export const loadSwayMspConfigFailure = createAction(
    '[Msp/API] Load Sway Msp Config Status Failure',
    props<{ error: any }>()
);


export const setOnboardTemplate = createAction(
    '[Templates/API] set onboard property',
    props<{ template_id: string, mspId: string, template_type: 'tenant' | 'group' }>()
);


export const setOnboardTemplateSuccess = createAction(
    '[Msp/API] set Msp Onboarding Status Success',
    props<{ msp_id: string, template_id: string, template_type: 'tenant' | 'group' }>()
);

export const setOnboardTemplateFailure = createAction(
    '[Msp/API] set Msp Onboarding Status Failure',
    props<{ error: any }>()
);

export const enableGlobalAutoRemediateConfig = createAction(
    '[Templates/API] enable global auto remediation config',
    props<{ mspId: string }>()
);

export const enableGlobalAutoRemediateConfigSuccess = createAction(
    '[Msp/API] enable global auto remediation config Success',
    props<{ msp_id: string }>()
);

export const enableGlobalAutoRemediateConfigFailure = createAction(
    '[Msp/API] enable global auto remediation config Failure',
    props<{ error: any }>()
);



export const disableGlobalAutoRemediateConfig = createAction(
    '[Templates/API] disable global auto remediation config',
    props<{ mspId: string, keep_old_values: boolean }>()
);

export const disableGlobalAutoRemediateConfigSuccess = createAction(
    '[Msp/API] disable global auto remediation config Success',
    props<{ msp_id: string }>()
);

export const disableGlobalAutoRemediateConfigFailure = createAction(
    '[Msp/API] disable global auto remediation config Failure',
    props<{ error: any }>()
);






export const deleteOnboardTemplate = createAction(
    '[Templates/API] delete onboard property',
    props<{ mspId: string , template_id:string}>()
);


export const deleteOnboardTemplateSuccess = createAction(
    '[Msp/API] delete Msp Onboarding Status Success',
    props<{ msp_id: string }>()
);

export const deleteOnboardTemplateFailure = createAction(
    '[Msp/API] delete Msp Onboarding Status Failure',
    props<{ error: any }>()
);
