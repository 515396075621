import { createAction, props } from '@ngrx/store';
import { MsolRole } from '../role-member/model';

export const loadRoles = createAction(
    '[Pwsh/Msol] Load Roles',
    props<{ _tenant: string }>()
);

export const loadRolesSuccess = createAction(
    '[Pwsh/Msol] Load MsolRoles Success',
    props<{ _tenant: string, msolRoles: MsolRole[] }>()
);

export const loadRolesFailure = createAction(
    '[Pwsh/Msol] Load Roles Failure',
    props<{ _tenant: string, error: any }>()
);
