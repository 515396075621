import { map, throwError } from 'rxjs';
import { AjaxError, AjaxResponse } from 'rxjs/ajax';

export function HandleAjaxResponse<T>() {
    return map((res: AjaxResponse<T>) => res.response);
}

export function HandleAjaxError(err: AjaxError) {
    let message = 'Request Error.';
    console.error(err);
    
    if (err.response) {
        if (err.response.message) {        
            message = err.response.message;
        } else if(err.response.error) {
            if(err.response.error.innerError){
                message = err.response.error.innerError.message || err.response.error.message;

            }else{

                message = err.response.error;
            }
        }
    }

    else if (err.message) {
        message = err.message;
    }
    
    const error = new Error(message);
    
    if(err?.response?.trace){
        error['trace'] = err.response.trace;
    }

    return throwError(() => error);
}
