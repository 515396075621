import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-multiple-selection-list',
    templateUrl: './multiple-selection-list.component.html',
    styleUrls: ['./multiple-selection-list.component.scss']
})
export class MultipleSelectionListComponent implements OnInit, OnDestroy {

    @Input() label: string = '';
    @Input() options: string[] = [];
    @Input() selection: string[] = [];
    @Output() selected = new EventEmitter<string[]>();

    subscription$: Subscription;
    myControl = new FormControl();


    constructor() { }

    ngOnInit(): void {

        this.subscription$ =
            this.myControl.valueChanges.subscribe(() => {
                this.selected.emit(this.myControl.value);
            });
    }

    ngOnDestroy() {
        if (!!this.subscription$) this.subscription$.unsubscribe();
    }
}
