import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { MspAjaxService } from 'src/app/services/ajax/msp-ajax.service';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import { Baseline } from 'src/app/stores/client/sway/baseline/model';
import { updateSwayGroupSuccess } from 'src/app/stores/client/sway/group/actions';
import { SwayTenant } from 'src/app/stores/client/sway/tenant/model';
import { TemplateItem } from '../items/model';
import { loadBaselinesSuccess, upsertBaselineItems, upsertBaselineItemsSuccess } from './../../../../../stores/client/sway/baseline/actions';
import { SwayGroup } from './../../../../../stores/client/sway/group/model';
import { updateSwayTenantSuccess } from './../../../../../stores/client/sway/tenant/actions';
import * as actions from './actions';
import { OctigaBestPracticeBeginId, SwayTemplate } from './model';

interface DetachResponse {
    baselines_response: Baseline[],
    tenant_response?: any
    group_response?: SwayGroup
}

@Injectable()
export class SwayTemplatesEffects {

    loadTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadTemplates),
            distinct(action => action.msp_id),
            mergeMap(({ msp_id }) => this.get(msp_id)
                .pipe(
                    map(templates => actions.loadTemplatesSuccess({ templates })),
                    catchError((error) => of(actions.loadTemplatesFailure({ error })))
                ))
        )
    );

    createTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.createTemplate),
            mergeMap(({ msp_id, _type, name }) => this.post(msp_id, { type: _type, name })
                .pipe(
                    map(template => actions.createTemplateSuccess({ template })),
                    catchError((error) => of(actions.createTemplateFailure({ error })))
                ))
        )
    );

    updateTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.updateTemplate),
            mergeMap(({ msp_id, id, name }) => this.put(msp_id, id, { name })
                .pipe(
                    map(template => actions.updateTemplateSuccess({ template })),
                    catchError((error) => of(actions.updateTemplateFailure({ error })))
                ))
        )
    );


    applyTemplate$ = createEffect(() => this.actions$.pipe(
        ofType(actions.applyTemplate),
        mergeMap(({ template_id, tenant_id, required_items, _type, group_id, ignored_items_ids }) => this.apply(tenant_id, template_id, required_items, _type, group_id, ignored_items_ids)
            .pipe(
                map(({ baselines, tenant, group }) => {
                    if (_type === 'tenant') {
                        this.store.dispatch(updateSwayTenantSuccess({ _tenant: tenant.id, tenant : {...tenant, group_order: JSON.parse(tenant.group_order)} }));
                    } else {
                        this.store.dispatch(updateSwayGroupSuccess({ _tenant: group.tenant_id, data: group }));
                    }

                    this.store.dispatch(upsertBaselineItems({ _tenant: tenant_id }));
                    return upsertBaselineItemsSuccess({ _tenant: tenant_id, baselines });
                }),
                catchError((error) => of(actions.applyTemplateFailure({ error })))
            ))
    ));


    apply(tenant_id: string, template_id: string, required_items: Array<Partial<TemplateItem>>, type: 'tenant' | 'group', group_id?: string , ignored_items_ids?: Array<string>) {
        return this.tenantAjax.post<{ baselines: Baseline[], tenant?: any, group: SwayGroup }>(tenant_id, `/api/sway/tenant/${tenant_id}/template/${template_id}?type=${type}&&group_id=${group_id}`, { required_items, ignored_items_ids });
    }

    deleteTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.deleteTemplate),
            mergeMap(({ msp_id, id, deleteBaselines }) => this.delete(msp_id, id, deleteBaselines)
                .pipe(
                    map(_ => actions.deleteTemplateSuccess({ id })),
                    catchError((error) => of(actions.deleteTemplateFailure({ error })))
                ))
        )
    );


    detachTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.detachTemplate),
            mergeMap(({ tenant_id, template_id, deleteBaselines, _type, group_id }) => this.detachTemplate(tenant_id, template_id, deleteBaselines, _type, group_id)
                .pipe(
                    mergeMap(({ baselines_response, tenant_response, group_response }) => [
                        actions.detachTemplateSuccess({ id: template_id }),
                        loadBaselinesSuccess({ _tenant: tenant_id, data: baselines_response }),

                        _type === 'tenant' ?
                            updateSwayTenantSuccess({ _tenant: tenant_id, tenant: {...tenant_response, group_order: JSON.parse(tenant_response.group_order)} })
                            : updateSwayGroupSuccess({ _tenant: tenant_id, data: group_response })
                    ]),
                    catchError((error) => of(actions.detachTemplateFailure({ error })))
                )
            )
        )
    );

    get(msp_id: string) {
        return this.ajax.get<{ records: SwayTemplate[] }>(`/api/sway/msp/${msp_id}/template`)
            .pipe(map(res => res.records),
                map(res => { // pull best practice Templates to the top
                    const bestPracticeItems = res.filter(res => res.msp_id.startsWith(OctigaBestPracticeBeginId) && res.id.startsWith(OctigaBestPracticeBeginId));
                    const otherTemplates = res.filter(res => !res.msp_id.startsWith(OctigaBestPracticeBeginId) && !res.id.startsWith(OctigaBestPracticeBeginId));

                    return [...bestPracticeItems, ...otherTemplates];
                })
            );
    }

    post(msp_id: string, body: Partial<SwayTemplate>) {
        return this.ajax.post<SwayTemplate>(`/api/sway/msp/${msp_id}/template`, body);
    }

    put(msp_id: string, template_id: string, body: Partial<SwayTemplate>) {
        return this.ajax.put<SwayTemplate>(`/api/sway/msp/${msp_id}/template/${template_id}`, body);
    }

    delete(msp_id: string, template_id: string, deleteBaselines: boolean) {
        return this.ajax.delete<any>(`/api/sway/msp/${msp_id}/template/${template_id}?deleteBaselines=${deleteBaselines}`);
    }

    detachTemplate(tenant_id: string, template_id: string, deleteBaselines: boolean, type: 'tenant' | 'group', group_id: string): Observable<DetachResponse> {
        return this.tenantAjax.delete<DetachResponse>(tenant_id, `/api/sway/tenant/${tenant_id}/template/${template_id}?deleteBaselines=${deleteBaselines}&&type=${type}&&group_id=${group_id}`);
    }

    constructor(
        private actions$: Actions,
        private ajax: MspAjaxService,
        private tenantAjax: TenantAjaxService,
        private store: Store<any>
    ) { }
}
