import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, distinct, map, mergeMap } from 'rxjs/operators';
import { TenantAjaxService } from 'src/app/services/ajax/tenant-ajax.service';
import * as actions from './actions';

@Injectable()
export class RolesStatusEffects {

    reload$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.reloadGraphRoles),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    map(roles => {
                        if (!roles || !Array.isArray(roles) || roles.length === 0) {
                            throw 'roles invalid';
                        } else {
                            return roles;
                        }
                    }),
                    map(data => actions.loadGraphRolesSuccess({ _tenant, data })),
                    catchError((error) => of(actions.loadGraphRolesFailure({ _tenant, error })))
                )
            )
        )
    );

    loadGraphRoles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.loadGraphRoles),
            distinct(action => action._tenant),
            mergeMap(({ _tenant }) => this.get(_tenant)
                .pipe(
                    map(roles => {
                        if (!roles || !Array.isArray(roles) || roles.length === 0) {
                            throw 'roles invalid';
                        } else {
                            return roles;
                        }
                    }),
                    map(data => actions.loadGraphRolesSuccess({ _tenant, data })),
                    catchError((error) => of(actions.loadGraphRolesFailure({ _tenant, error })))
                )
            )
        )
    );

    private get(tenant: string): Observable<string[]> {
        return this.ajax.get(tenant, '/api/fabric/status/client/roles');
    }

    constructor(
        private actions$: Actions,
        private ajax: TenantAjaxService
    ) { }

}
