import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, switchMap, timer } from 'rxjs';
import { deleteSession } from 'src/app/stores/root/octiga/session/actions';
import { Session } from 'src/app/stores/root/octiga/session/model';
import { OkDialogData, OkDialogComponent } from '../modules/shared/components/ok-dialog/ok-dialog.component';
import { selectSession } from '../stores/root.store';

function minutes(num: number) {
    return 1000 * 60 * num;
}

function EpochDeltaWithinMinutes(epoch1: number, epoch2: number, num: number) {
    return (epoch1 - epoch2) < minutes(num);
}

@Injectable({
    providedIn: 'root'
})
export class SessionCheckService {

    interval_minutes = 1; // interval of checks should also be used to determine delta window

    public isExpired: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private store: Store,
        private dialog: MatDialog
    ) { }

    public init() {
        timer(0, minutes(this.interval_minutes))
            .pipe(
                switchMap(() => this.store.select(selectSession)),
                filter(state => !state.loading && state.isAuthenticated)
            )
            .subscribe(state => {

                const hasExpired = this.checkExpired(state.session);

                if (hasExpired) {
                    this.store.dispatch(deleteSession({ microsoftSignout: false }));
                    this.isExpired.next(true);
                    this.openExpireDialog();
                }

            });
    }

    checkExpired(session: Session | null) {
        if (session === null) return false;
        const exp_epoch = session.exp;
        const now_epoch = (new Date).getTime();
        return EpochDeltaWithinMinutes(exp_epoch, now_epoch, this.interval_minutes);
    }

    openExpireDialog() {
        const data: OkDialogData = {
            title: 'Session expired',
            description: 'Please sign in again to continue.',
            confirm_button_text: 'Sign In',
            confirm_button_class: 'primary'
        };

        this.dialog.open(OkDialogComponent, {
            width: '40rem',
            data,
            disableClose: true
        });

    }

}
