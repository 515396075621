import { retryWhen, delay, tap, map } from 'rxjs/operators';

export function retry(millisecond: number, count: number, msg: string) {
    return retryWhen(error => {
        return error
            .pipe(
                delay(millisecond),
                map(res => {
                    count--;
                    if (count === 0) {
                        console.log('XHR retry limit reached');
                        throw (msg);
                    } else {
                        console.log('retrying XHR call');
                        return res;
                    }
                })
            );
    });
}
