import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AllowList, Tenant } from 'src/app/interfaces/tenant.interface';
import { updateAllowlist } from 'src/app/stores/client/octiga/tenant/actions';
import { Countries } from 'src/app/utils/ISO-3166-Countries';

@Component({
    selector: 'app-countries-dialog',
    templateUrl: './countries-dialog.component.html',
    styleUrls: ['./countries-dialog.component.scss']
})
export class CountriesDialogComponent implements OnInit {

    selected_countries: string[];
    pre_selected_countries: string;
    countries: string[] = Countries.map(c => c.name);
    expanded = false;

    constructor(
        private store: Store,
        @Inject(MAT_DIALOG_DATA) public data: { tenant: Tenant },
        private ref: MatDialogRef<CountriesDialogComponent>
    ) { }

    ngOnInit(): void {

        if (this.data.tenant.allowlist) {
            const allowed = this.data.tenant.allowlist.filter(item => item.type === 'country');
            const selected_allowed = allowed.map(item => item.name);
            const pre_selected_allowed = allowed.filter(item => item.added_during_registration).map(item => item.name);
            this.selected_countries = selected_allowed;
            this.pre_selected_countries = pre_selected_allowed.join(', ');
        } else {
            this.selected_countries = [];
        }

    }

    expandSelectCountriesList() {
        this.expanded = !this.expanded;
    }

    countriesChanged(values: string[]) {
        this.selected_countries = values;
    }

    save() {
        const data: AllowList[] = [];

        for (const country of this.selected_countries) {
            data.push({
                'active': true,
                'bypass': {
                    'fraudIP': false,
                    'mobile': false,
                    'tor': false,
                    'vpn': false
                },
                'country_code': Countries.find(c => c.name === country)['alpha-2'],
                'name': country,
                'type': 'country'
            });
        }

        this.store.dispatch(updateAllowlist({ _tenant: this.data.tenant.id, data }));
        this.ref.close(true);
    }

}
